<template>
  <template v-if="printInvoices">
    <header class="bg-success">
      <img
        id="logo"
        class="media"
        :src="resource + printInvoices.company.logo"
      />
    </header>
    <!-- <div
      v-if="printInvoices.company.invoice_header != ''"
      style="text-align: justify; margin-bottom: 5px"
    >
      <h3 style="margin-bottom: 0"><u>Detail</u></h3>
      <p>
        {{ printInvoices.company.invoice_header }}
      </p>
    </div> -->

    <h3 style="margin: 0">
      <u>Receipt</u> :
      <u>N<sup>o</sup> : {{ printInvoices.last_invoice.code }}</u>
    </h3>
    <table class="bill-details">
      <tbody>
        <tr>
          <td>
            Date :
            <small>{{
              new Date(printInvoices.last_invoice.date).toLocaleDateString(
                "en-AU",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            }}</small>
          </td>
          <td style="text-align: center;">
            Status :
            <span>{{
              printInvoices.last_invoice.paid_status == 1
                ? "Paid"
                : printInvoices.last_invoice.paid_status == 2
                ? "Unpaid"
                : "Due"
            }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Customer : <small>{{ printInvoices.last_invoice.cus_name }}</small>
          </td>
          <td style="text-align: center;" v-if="printInvoices.last_invoice.paid_status != 2">
            Paid By:
            <span>{{ printInvoices.payment.payment_method_name }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Sale By: <span>{{ printInvoices.last_invoice.nickname }}</span>
          </td>
        </tr>
        <tr>
          <td v-if="printInvoices.last_invoice.note" style="padding-bottom: 7px">
            Note: <span>{{ printInvoices.last_invoice.note }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="items">
      <thead>
        <tr>
          <th class="heading name" style="font-size: 8px !important">Item</th>
          <th class="heading" style="font-size: 8px !important">Price</th>
          <th class="heading qty" style="font-size: 8px !important">Qty</th>
          <th class="heading amount" style="font-size: 8px !important">Sub Total</th>
          <th class="heading" style="font-size: 8px !important">Dis</th>
          <th class="heading amount" style="font-size: 8px !important">Total</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="data in printInvoices.invoiceDetails" :key="data.id">
          <td>
            {{ data.pos_product_name }}
            <br />
            <template v-if="JSON.parse(data.attribute).length > 0">
              <small>
                [
                <sub
                  v-for="(item, index) in JSON.parse(data.attribute)"
                  :key="index"
                >
                  {{ item.value }}
                  {{
                    index == JSON.parse(data.attribute).length - 1 ? "" : ", "
                  }}
                </sub>
                ]
              </small>
            </template>
          </td>
          <td class="price">
            {{ formatToCurrency(Number(data.unit_price) + Number(data.attribute_increment), 2) }}
          </td>
          <td>{{ data.quantity }}</td>
          <td class="price">{{ formatToCurrency(data.sub_total, 2) }}</td>
          <td class="price">{{ formatToCurrency(data.total_discount, 2) }}</td>
          <td class="price">{{ formatToCurrency(data.total_price, 2) }}</td>
        </tr>
        <tr>
          <td colspan="5" class="sum-up line">Sub total (exclude pro_disc)</td>
          <td class="line price">
            {{ formatToCurrency(printInvoices.last_invoice.sub_total, 2) }}
          </td>
        </tr>
        <tr>
          <td colspan="5" class="sum-up">Product Disc</td>
          <td class="price">
            {{ formatToCurrency(printInvoices.last_invoice.product_discount, 2) }}
          </td>
        </tr>
        <tr>
          <td colspan="5" class="sum-up">Shipping</td>
          <td class="price">
            {{ formatToCurrency(printInvoices.last_invoice.shipping, 2) }}
          </td>
        </tr>
        <tr>
          <td colspan="5" class="sum-up">Tax</td>
          <td class="price">
            {{ formatToCurrency(printInvoices.last_invoice.tax, 2) }}
          </td>
        </tr>
        <tr>
          <td colspan="5" class="sum-up">Customer Discount</td>
          <td class="price">
            {{
              formatToCurrency(
                printInvoices.last_invoice.cus_amount_discount,
                2
              )
            }}
          </td>
        </tr>
        <tr>
          <th class="total total-top" style="text-align: left">Total ($)</th>
          <th
            colspan="5"
            class="total total-top text price"
            style="text-align: right"
          >
            {{ formatToCurrency(printInvoices.last_invoice.total_usd, 2) }}
          </th>
        </tr>
        <tr>
          <th class="total" style="text-align: left">Total (៛)</th>
          <th colspan="5" class="total" style="text-align: right">
            {{ formatToCurrency(printInvoices.last_invoice.total_khr, 0) }} ៛
          </th>
        </tr>
        <template v-if="printInvoices.last_invoice.paid_status != 2">
            <tr v-if="printInvoices.payment.exchange_rate_khr != ''">
                <td colspan="6" class="sum-up total-bottom">
                    Exchange : ${{ printInvoices.payment.exchange_rate_usd }} =
                    {{ formatToCurrency(printInvoices.payment.exchange_rate_khr, 0) }}៛
                </td>
            </tr>
        </template>
        <tr v-if="printInvoices.last_invoice.paid_status == 3">
          <th class="total" style="text-align: left">Paid ($)</th>
          <th colspan="5" class="total price" style="text-align: right">
            {{ formatToCurrency(printInvoices.last_invoice.paid, 2) }}
          </th>
        </tr>
        <tr v-if="printInvoices.last_invoice.paid_status == 2 || printInvoices.last_invoice.paid_status == 3">
          <th class="total total-bottom" style="text-align: left">Debt ($)</th>
          <th colspan="5" class="total total-bottom price" style="text-align: right">
            {{ formatToCurrency(printInvoices.last_invoice.debt, 2) }}
          </th>
        </tr>
        <tr v-if="printInvoices.last_invoice.paid_status != 2">
          <td class="sum-up">Recived ($)</td>
          <td colspan="5" class="price">
            {{ formatToCurrency(printInvoices.payment.amount_receive_usd, 2) }}
          </td>
        </tr>
        <tr v-if="printInvoices.last_invoice.paid_status != 2">
          <td class="sum-up total-bottom">Recived (៛)</td>
          <td colspan="5" class="price-riel total-bottom">
            {{ formatToCurrency(printInvoices.payment.amount_receive_khr, 0) }}
          </td>
        </tr>
        <tr v-if="printInvoices.last_invoice.paid_status != 2">
          <td class="sum-up">Return ($)</td>
          <td colspan="5" class="price">
            {{ formatToCurrency(printInvoices.payment.extra_amount_usd, 2) }}
          </td>
        </tr>
        <tr v-if="printInvoices.last_invoice.paid_status != 2">
          <td class="sum-up total-bottom">Return (៛)</td>
          <td colspan="5" class="price-riel total-bottom">
            {{ formatToCurrency(printInvoices.payment.extra_amount_khr, 0) }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <section>
        <p>
            Paid by : <span>CASH</span>
        </p>
        <p>
            type : <span>Walkin</span>
        </p>
        <p style="text-align:center">
            Thank you for your visit!
        </p>
    </section> -->
    <footer>
        <div style="text-align: center;">
            <!-- <h5 style="margin: 0; padding: 0">
                {{ printInvoices.company.kh_name }}
            </h5> -->
            <p 
                v-if="printInvoices.company.invoice_footer != ''"
            >
                    {{ printInvoices.company.invoice_footer }}
            </p>
            <p>
                <b>
                    <small>{{ printInvoices.company.en_name }}</small>
                </b>
            </p>
            <!-- <h3 style="margin: 0"><u>Contact Info</u></h3> -->
            <p>
                <small>{{ printInvoices.company.address }}</small>
            </p>
            <p>
                <small>{{ printInvoices.company.email }}</small>
            </p>
            <p>
                <span v-if="printInvoices.company.phone_line1">
                    <small>{{ printInvoices.company.phone_line1 }}</small>
                </span>
                <span v-if="printInvoices.company.phone_line2">
                    <small>, {{ printInvoices.company.phone_line2 }}</small>
                </span>
                <span v-if="printInvoices.company.phone_line3">
                    <small>, {{ printInvoices.company.phone_line3 }}</small>
                </span>
                <span v-if="printInvoices.company.phone_line4">
                    <small>, {{ printInvoices.company.phone_line4 }}</small>
                </span>
            </p>
            <p>
                សូមអរគុណសូមអញ្ជើញមកម្ដងទៀត
            </p>
            <p>Copyright &#169; www.nexttechcambodia.com</p>
        </div>
    </footer>
  </template>
</template>

<script>
import { resource } from "@/networks/domain";
export default {
  props: {
    printInvoices: {
      type: [Array, Object],
      default: [],
    },
  },
  data() {
    return {
      resource: resource,
    };
  },
  methods: {
    formatToCurrency(amount, fixed = 2) {
      if (fixed > 0) {
        return Number(amount)
          .toFixed(fixed)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        let luy = Number(amount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        let new_luy = luy.split(".");
        return new_luy[0];
      }
    },
  },
};
</script>

<style scoped>
/* table {
  width: 100% !important;
}

tr {
  width: 100% !important;
} */

h1 {
  text-align: center !important;
  vertical-align: middle !important;
}

#logo {
  width: 60% !important;
  text-align: center !important;
  -webkit-align-content: center !important;
  align-content: center !important;
  display: block !important;
  margin: 0 auto !important;
  transform: scale(0.6);
}

header {
  width: 100% !important;
  text-align: center !important;
  -webkit-align-content: center !important;
  align-content: center !important;
  vertical-align: middle !important;
}

.items thead {
  text-align: center !important;
}

.center-align {
  text-align: center !important;
}

.bill-details td {
  font-size: 12px !important;
}

.receipt {
  font-size: medium !important;
}

.items .heading {
  font-size: 12.5px !important;
  text-transform: uppercase !important;
  border-top: 1px solid black !important;
  margin-bottom: 4px !important;
  border-bottom: 1px solid black !important;
  vertical-align: middle !important;
}

.items thead tr th:first-child,
.items tbody tr td:first-child {
  width: 47% !important;
  min-width: 47% !important;
  max-width: 47% !important;
  word-break: break-all !important;
  text-align: left !important;
}

.items td {
  font-size: 8px !important;
  text-align: right !important;
  vertical-align: bottom !important;
}

.price::before {
  content: "$";
  font-family: Arial;
  text-align: right;
}

.price-riel::after {
  content: " ៛";
  font-family: Arial;
}

.price-riel {
  content: "៛";
  font-family: Arial;
  text-align: right;
}

.sum-up {
  text-align: right !important;
}

.total {
  font-size: 13px;
}

.total-bottom {
  border-bottom: 1px dashed black !important;
}

.total-top {
  border-top: 1px dashed black !important;
}

.total.text,
.total.price {
  text-align: right;
}

.total.price::before {
  content: "$";
}

.line {
  border-top: 1px solid black !important;
}

.heading.rate {
  width: 20%;
}

.dis::before {
  content: "%";
}

.heading.amount {
  width: 25%;
}

.heading.qty {
  width: 5%;
}

p {
  padding: 1px;
  margin: 0;
}

/* td {
        text-align: center !important;
    }
    th {
        text-align: center !important;
    } */

section,
footer {
  font-size: 12px;
}
/* table {
  page-break-inside: auto !important;
}
tr {
  page-break-inside: avoid !important;
  page-break-after: auto !important;
} */

</style>
