import { createStore } from 'vuex'

export default createStore({
  state: {
    app: {
      name: 'Next Tech Cambodia',
      phone: '16 855002',
      api_key: '$2y$10$VB87rm1lo/Sw7HbNa5ydAeBM2i.vDTw2b8xojuDuv1NJg1VrLIDOe',
      type : 'core_system',
      appId : '4e123a77-b9f8-4695-b07a-03151c5802b6'
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
