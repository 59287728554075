import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'
import MainLayout from '@/layouts/MainLayout'
import HomeView from '../views/dashboards/HomeView.vue'
import DetailView from '../views/DetailView.vue'
import LoginView from '../views/auth/LoginView.vue'
import MessageView from '../views/MessageView.vue'
import ErrorPage from '../views/404/ErrorPage.vue'
import createStore from '../store/index.js'
import Unauthenticated from '../views/401/Unauthenticated.vue'
import ServiceDown from '../views/503/ServiceDown.vue'
import AddSaleView from '../views/sales/AddSaleView.vue'
import CompanyCategoryView from '@/views/categories/client/CompanyCategoryView'
import SystemCategoryView from '@/views/categories/owner/SystemCategoryView'
import PermissionView from '@/views/Main_System/permissions/PermissionView'
import RoleOwnerView from '@/views/roles/owner/RoleOwnerView'
import RoleClientView from '@/views/roles/client/RoleClientView'
import RolePermissionView from '@/views/role_permissions/owner/RolePermissionView'
import SystemServiceView from '@/views/systems/SystemServiceView'
import CompanyRolePermissionView from '@/views/role_permissions/client/CompanyRolePermissionView'
import ExchangeRateView from '@/views/exchange_rate/ExchangeRateView'
import PaymentMethodView from '@/views/payment_methods/PaymentMethodView'
// import UserView from '@/views/users/UserView'
import UnitTypeView from '@/views/unit_types/UnitTypeView.'
import UserDetailView from '@/views/users/UserDetailView'
import CompanyListView from '@/views/companys/CompanyListView'
import CompanyListDetailView from '@/views/companys/CompanyListDetailView'
import CompanyInfoView from '@/views/companys/CompanyInfoView'
import EditCompanyInfoView from '@/views/companys/EditCompanyInfoView'
import AboutMeView from '@/views/aboutMe/AboutMeView'
import ProductView from '@/views/products/ProductView'
import ProductAttributeView from '@/views/products/ProductAttributeView'
import AttributeView from '@/views/productAttributes/AttributeView'
import StockInView from '@/views/stockins/StockInView'
import SupplierView from '@/views/suppliers/SupplierView'
import LowStockView from '@/views/lowStocks/LowStockView'
import OutOfStockView from '@/views/out_of_stocks/OutOfStockView'
import CustomerView from '@/views/customers/CustomerView'
import CustomerTypeView from '@/views/customerTypes/CustomerTypeView'
import StockOutView from '@/views/stockOuts/StockOutView'
import ExpenseCategoryView from '@/views/expenseCategories/ExpenseCategoryView'
import ProductDiscountView from '@/views/productDiscounts/ProductDiscountView'
import ExpenseView from '@/views/expenses/ExpenseView'
import VatView from '@/views/vats/VatView'
import IncomeExpenseReportView from '@/views/Reports/IncomeExpenseReportView'
import SaleReportView from '@/views/Reports/SaleReportView'
import ExpenseReportView from '@/views/Reports/ExpenseReportView'
import PosInvoiceView from '@/views/Pos/invoices/PosInvoiceView'
import MainCategoryView from '@/views/Main_System/mainCategory/MainCategoryView'
import ProductBrandView from '@/views/Main_System/productBrands/ProductBrandView'
import ProductBarcodeView from '@/views/Pos/productBarcodes/ProductBarcodeView'
import StockInReportView from '@/views/Reports/StockInReportView'
import StockOutReportView from '@/views/Reports/StockOutReportView'
import InvoiceReportView from '@/views/Reports/InvoiceReportView'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children : [
      {
        path: '',
        name: 'dashboard',
        component: HomeView,
      },
      // {
      //   path: '/about',
      //   name: 'about',

      //   component: function () {
      //     return import('../views/AboutView.vue')
      //   }
      // },
      // {
      //   path: '/detail',
      //   name: 'detail',
      //   component: DetailView,
      //   props: true
      // },
      // {
      //   path: '/message',
      //   name: 'message',
      //   component: MessageView
      // },
      {
        path: '/permission',
        name: 'permission',
        component: PermissionView
      },
      {
        path: '/role',
        name: 'role',
        component: RoleOwnerView
      },
      {
        path: '/role-permission',
        name: 'role-permission',
        component: RolePermissionView,
        props : true
      },
      {
        path: '/company-list',
        name: 'company-list',
        component: CompanyListView
      },
      {
        path: '/company-list-detail/:id',
        name: 'company-list-detail',
        component: CompanyListDetailView
      },
      {
        path: '/company-role',
        name: 'company_role',
        component: RoleClientView
      },
      {
        path: '/company-role-permission',
        name: 'company_role_permission',
        component: CompanyRolePermissionView,
        props : true
      },
      {
        path: '/product-category',
        name: 'product-category',
        component: CompanyCategoryView
      },
      {
        path: '/system-category',
        name: 'system-category',
        component: SystemCategoryView
      },
      {
        path: '/system-service',
        name: 'system-service',
        component: SystemServiceView 
      },
      {
        path: '/pos-exchange-rate',
        name: 'pos-exchange-rate',
        component: ExchangeRateView 
      },
      {
        path: '/payment-method',
        name: 'payment-method',
        component: PaymentMethodView 
      },
      {
        path: '/user',
        name: 'user',
        // component: UserView 
        component: function () {
          return import('@/views/users/UserView')
        }
      },
      {
        path: '/user-detail',
        name: 'user-detail',
        component: UserDetailView ,
        props : true
      },
      {
        path: '/unit-type',
        name: 'unit-type',
        component: UnitTypeView 
      },
      {
        path: '/company-info',
        name: 'company-info',
        component: CompanyInfoView
      },
      {
        path: '/edit-company-info',
        name: 'edit-company-info',
        component: EditCompanyInfoView
      },
      {
        path: '/about-me',
        name: 'about-me',
        component: AboutMeView
      },
      {
        path: '/product',
        name: 'product',
        component: ProductView
      },
      {
        path: '/product/attribute',
        name: 'product-attribute',
        component: ProductAttributeView,
        props : true
      },
      {
        path: '/product-attribute',
        name: 'attribute',
        component : AttributeView
      },
      {
        path: '/stock-in',
        name: 'stock-in',
        component : StockInView
      },
      {
        path: '/supplier',
        name: 'supplier',
        component : SupplierView
      },
      {
        path: '/low-stock',
        name: 'low-stock',
        component : LowStockView
      },
      {
        path: '/out-of-stock',
        name: 'out-of-stock',
        component : OutOfStockView
      },
      {
        path: '/customer',
        name: 'customer',
        component : CustomerView
      },
      {
        path: '/customer-type',
        name: 'customer-type',
        component : CustomerTypeView
      },
      {
        path: '/stock-out',
        name: 'stock-out',
        component : StockOutView
      },
      {
        path: '/expense-category',
        name: 'expense-category',
        component : ExpenseCategoryView
      },
      {
        path: '/expense',
        name: 'expense',
        component : ExpenseView
      },
      {
        path: '/product-discount',
        name: 'product-discount',
        component : ProductDiscountView
      },
      {
        path: '/pos-vat',
        name: 'vat',
        component : VatView
      },
      {
        path: '/pos/income-expense-report',
        name: 'pos-income-expense-report',
        component : IncomeExpenseReportView
      },
      {
        path: '/pos/income-report',
        name: 'pos-income-report',
        component : SaleReportView
      },
      {
        path: '/pos/expense-report',
        name: 'pos-expense-report',
        component : ExpenseReportView
      },
      {
        path: '/pos/invoice-report',
        name: 'pos-invoice-report',
        component : InvoiceReportView
      },
      {
        path: '/pos-invoice',
        name: 'pos-invoice',
        component : PosInvoiceView
      },
      {
        path: '/main-category',
        name: 'main-category',
        component : MainCategoryView
      },
      {
        path: '/product-brand',
        name : 'product-brand',
        component : ProductBrandView
      },
      {
        path: '/pos-product-barcode',
        name : 'pos-product-barcode',
        component : ProductBarcodeView
      },
      {
        path : '/pos/stock-in-report',
        name : 'pos-stock-in-report',
        component : StockInReportView
      },
      {
        path : '/pos/stock-out-report',
        name : 'pos-stock-out-report',
        component : StockOutReportView
      }
    ],
  },
  {
    path: '/pos/add-sale',
    name: 'pos-add-sale',
    component: AddSaleView
  },
  {
    path: '/logout',
    name: 'logout',
    component: function () {
      return import('@/views/auth/LogoutView.vue');
    }
  },
  {
    path: '/Unauthenticated',
    name: 'Unauthenticated',
    component: Unauthenticated
  },
  {
    path: '/server-down',
    name: 'server-down',
    component: ServiceDown
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/:catchAll(.*)',
    // redirect : '/'
    name: 'ErrorPage',
    component: ErrorPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


router.beforeEach((to, from, next) => {

  document.title = `${to.name.toUpperCase()} | ${createStore.state.app.name}`; 
    // $(".sidebar li>ul").css({'display' : 'none'});
  $(".sidebar li").removeClass("menu-open");
  $(".sidebar li>a").removeClass("active");
  $(".sidebar>ul li>a").removeClass("active");


  if(to.name == 'Unauthenticated'){
    next('/login')
  }
  else if(to.name == 'ErrorPage'){
    if(localStorage.getItem('posUser')){
      document.title = `${from.name.toUpperCase()} | ${createStore.state.app.name}`; 
      next(from.path)
    } else {
      next('/login')
    }
  }
  else if(to.name == 'pos-invoice' || to.name == 'pos-add-sale'){
    next();
  }
  else {
    //remove css barcode print for page is differrent with barcode and invoioce page
    const removeLinkId = document.querySelectorAll('#removeLink');
    if(removeLinkId){
      removeLinkId.forEach(e => {
        e.remove()
      })
    }
    setTimeout(()=>{
      $('#belugar').attr('media',"all");
    },100)

    next();
  }
});
export default router
