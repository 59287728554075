<template>
    <content-header title="Invoice Report" icon="fa fa-angle-double-right"/>
    <content>
      <loading v-if="isTrue"/>
      <div v-else class="container-fluid">
        <div class="card">
  
  
  
          <!-- button action -->
          <div class="card-header pt-0">
            <div class="form-group w-sm-50 float-sm-left mb-0 mt-3">
                <select v-model="check_date" @change="clicker_date()" class="form-control selectPicker txt-kh" data-style="bg-system text-white">
                    <option value="">Select Range Date</option>
                    <option value="today">Today</option>
                    <option value="this_week">This Week</option>
                    <option value="this_month">This Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_week">Last Week</option>
                    <option value="last_two_week">Last two Week</option>
                    <option value="last_month">Last Month</option>
                    <option value="last_three_month">Last Three Month</option>
                    <option value="last_six_month">Last Six Month</option>
                    <option value="last_year">Last Year</option>
                </select>
            </div>
            
            
            <div class="form-group float-sm-right mb-0 mt-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="far fa-calendar-alt"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control float-right" id="reservation">
                    <div class="input-group-prepend" @click="clickRangeDate()">
                        <span class="input-group-text rounded-right border-left-0">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </span>
                    </div>
                </div>
            </div>
          </div>
  
  
  
          <div class="card-body">
            <div class="row my-3 mx-0">
                <div v-if="table.data.companies" class="col-lg-8 col-sm-8 d-sm-block d-none d-flex justify-content-center align-content-center">
                    <div class="row" style="box-sizing: border-box;">
                        <div class="col-lg-2 col-sm-12 mr-lg-3 mb-3 display-phone">
                            <img v-if="table.data.companies.logo" :src="resource + table.data.companies.logo" width="100" class="rounded-circle">
                        </div>
                        <div class="col-lg-8 col-sm-12 display-phone">
                            <span class="kh_muol p-0 d-block"> {{ table.data.companies.kh_name }}</span>
                            <span class="kh_content p-0 d-block"> {{ table.data.companies.en_name }}</span>
                            <span class="kh_content p-0 d-block">Address ៖ {{ table.data.companies.address }}</span>
                            <span class="kh_content p-0">Telephone ៖ {{ table.data.companies.phone_line1 }}</span>
                            <span v-if="table.data.companies.phone_line2"  class="kh_content p-0 ">, {{ table.data.companies.phone_line2 }}</span>
                            <span v-if="table.data.companies.phone_line3"  class="kh_content p-0 ">, {{ table.data.companies.phone_line3 }}</span>
                            <span v-if="table.data.companies.phone_line4"  class="kh_content p-0 ">, {{ table.data.companies.phone_line4 }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="table.data.companies" class="col-lg-4 col-sm-4 p-0">
                    <table width="100%" class="table-detail">
                        <tbody>
                            <tr class="border-bot">
                                <td class="text-left kh_muol" colspan="3">
                                    <h4>Invoice Report</h4>
                                </td>
                            </tr>
                            <tr class="border-bot">
                                <td class="khos text-left">From Date</td>
                                <td class="text-right">:</td>
                                <td class="text-right">{{ new Date(table.data.from).toLocaleDateString("en-GB", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric' }) }}</td>
                            </tr>
                            <tr class="border-bot">
                                <td class="khos text-left">To Date</td>
                                <td class="text-right">:</td>
                                <td class="text-right">{{ new Date(table.data.to).toLocaleDateString("en-GB", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric' }) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
  
  
            <!-- table  -->
            <div  class="table-responsive-sm position-relative">
                <table v-if="table.data.data.length != 0" class="table data-table table-sm table-bordered" style="width: 100%">
                    <tr>
                        <td colspan="2" data-col="=Summary" class="text-center text-md bg-system text-white text-md text-bold">SUMMARY</td>
                    </tr>
                    <tr>
                        <td class="d-none"></td>
                        <td data-col="#" class="text-center text-md"> Total Cost
              
                        </td>
                        <td data-col="Total Original Price" class="  text-md"> $ {{ totalOriginalPrice }} </td>
                    </tr>
                    <tr>
                        <td class="d-none"></td>
                        <td data-col="#" class="text-center text-md"> Total Price
                 
                        </td>
                        <td data-col="Total Sale Price" class="  text-md"> $ {{ totalSalePrice }} </td>
                    </tr>  
                    <tr :id="`tr${table.data.length}table`">
                        <td class="d-none"></td>
                        <td data-col="#" class="text-center text-md bg-warning"> Total Profit
               
                        </td>
                        <td data-col="Profit" class="text-md bg-warning"> $ {{ totalProfit }} </td>
                    </tr> 
                    <tr :id="`tr${table.data.length}table`">
                        <td class="d-none"></td>
                        <td data-col="#" class="text-center text-md bg-danger"> Total Debt
                  
                        </td>
                        <td data-col="Total Debt Price" class="bg-danger  text-md"> $ {{ totalDebtPrice }} </td>
                    </tr> 
                    <tr :id="`tr${table.data.length}table`">
                        <td class="d-none"></td>
                        <td data-col="#" class="text-center text-md bg-dark"> Total Sale / Cash On Hand
             
                        </td>
                        <td data-col="Total Sale / Cash On Hand" class="  text-md bg-dark"> $ {{ totalGetPrice }} </td>
                    </tr> 
                </table>
                <br>
              <table class="table data-table table-sm table-bordered" style="width: 100%">
                <thead class="bg-system text-light">
                    <tr>
                        <th colspan="6" class="bg-system">
                            <h5 class="m-0 text-md text-bold">Details</h5>
                        </th>
                    </tr>
                  <tr>
                    <th style="width:50px" class="d-none"></th>
                    <th style="width:40px" class="d-none">#</th>
                    <th style="width:150px">Invoice No.</th>
                    <th>Cost</th>
                    <th style="width:150px">Price</th>
                    <th style="width:100px">Profit</th>
                    <th style="width:150px">Sale / Cash On Hand</th>
                    <th style="width:130px">Debt Amount</th>
                  </tr>
                </thead>
                <tbody v-if="table.data.data.length != 0">
                  <template v-for="(data, index) in table.data.data" :key="index">
                    <tr :id="`tr${index}table`">
                      <td class="d-none"></td>
                      <td class="d-none">{{ index + 1}}</td>
                      <td :data-col="'Invoice No.'"> {{ data.code }}
                        <button :data-row = "`tr${index}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                          <i class="fas fa-plus-circle"></i>
                        </button>
                      </td>
                      <td data-col="Cost">$ {{ formatToCurrency(Number(data.totalOriginalPrice),2) }}</td>
                      <td data-col="Price">$ {{ formatToCurrency(Number(data.total_usd),2) }}</td>
                      <td data-col="Profit">$ {{ formatToCurrency(Number(data.total_usd) - Number(data.totalOriginalPrice),2) }}</td>
                      <td data-col="Sale">$ {{ formatToCurrency(Number(data.totalGetPrice),2) }}</td>
                      <td data-col="Debt Amount">$ {{ formatToCurrency(Number(data.debt),2) }}</td>
                    </tr> 
                  </template>  
                    <tr id="trtable">
                        <td class="d-none"></td>
                        <td class="d-none"></td>
                        <td colspan="1" data-col="#" class="text-right bg-gray text-lg" style="letter-spacing: .1em; text-transform: uppercase !important;"> TOTAL
                            <button data-row = "trtable" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </td>
                        <td data-col="Total Cost" class=" bg-gray text-lg"> $ {{ totalOriginalPrice }} </td>
                        <td data-col="Total Price" class=" bg-gray text-lg"> $ {{ totalSalePrice }} </td>
                        <td data-col="Total Profit" class=" bg-gray text-lg"> $ {{ totalProfit }} </td>
                        <td data-col="Total Sale" class=" bg-gray text-lg"> $ {{ totalGetPrice }} </td>
                        <td data-col="Total Debt Price" class=" bg-gray text-lg"> $ {{ totalDebtPrice }} </td>
                    </tr>
                </tbody>
              </table>
            </div>
  

            <!-- Processing loader  -->
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>

            <!-- no data -->
            <div v-if="table.data.data.length == 0" class="card">
                <div class="card-body text-center">
                  <span class="txt-system">No Data...</span>
                </div>
            </div>
  
  
          </div>
        </div>
      </div>
    </content>
    <content-header/>
  </template>
  
  <script>
  import axios from 'axios'
  import { resource } from '@/networks/domain'
  import { invoice } from '@/networks/services/pos/reports/invoice.service'
  import { checkPermission } from '@/networks/services/helper/helper.js'
  import Loading from '@/components/loading/Loading'
  import { config } from '@/networks/config'
  
  
  
  export default {
    components: { 
      Loading,
    },
    data(){
      return {
        table : {
          data: [],
        },

        check_date : 'today',
        resource : resource,
        fromDate : '',
        toDate : '',
  
        permission_no: false,
        isTrue: true,
        pageLoad: false,
        create: false,
        deleteAll : false,
        edit: false,
        list: false,
      }
    },
    computed : {
        totalOriginalPrice(){
            let data = this.table.data.data;
            let amount = 0;
            data.forEach(item => {
                amount += Number(item.totalOriginalPrice);
            });

            return this.formatToCurrency(amount,2);
        },
        totalSalePrice(){
            let data = this.table.data.data;
            let amount = 0;
            data.forEach(item => {
                amount += Number(item.total_usd);
            });

            return this.formatToCurrency(amount,2);
        },
        totalGetPrice(){
            let data = this.table.data.data;
            let amount = 0;
            data.forEach(item => {
                amount += Number(item.totalGetPrice);
            });

            return this.formatToCurrency(amount,2);
        },
        totalDebtPrice(){
            let data = this.table.data.data;
            let amount = 0;
            data.forEach(item => {
                amount += Number(item.debt);
            });


            return this.formatToCurrency(amount,2);
        },
        totalProfit(){
            let data = this.table.data.data;

            let profit = 0;
            data.forEach(item => {
                const cal = Number(item.total_usd) - Number(item.totalOriginalPrice);
                profit +=  cal;
            });

            return this.formatToCurrency(profit,2);
        },
    },
    methods : {
        formatToCurrency(amount,fixed = 2){
            if(fixed > 0){
                let luy = 0;
                if(Number(amount) < 0){
                    luy = -Number(amount);
                } else {
                    luy = Number(amount);
                }
                return Number(luy).toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            } else {
                let luy = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let new_luy = luy.split(".");
                return new_luy[0]; 
            }
        },
        clicker_date(){
            $('#reservation').val('');
            this.fromDate = '';
            this.toDate = '';
            this.pageLoad = true;
            this.getData(false);
        },
        clickRangeDate(){
            this.check_date = '',
            this.fromDate = $('#reservation').data('daterangepicker').startDate.format("YYYY-MM-DD");
            this.toDate = $('#reservation').data('daterangepicker').endDate.format("YYYY-MM-DD");
            this.pageLoad = true;
            this.getData(false);
        },
        async reloadSelectOption(){
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');
            $('.selectPicker').selectpicker();
        },
        async getData(id){
            if(localStorage.getItem('posUser')){
                config();
                axios
                    .get(`${invoice(this.check_date,this.fromDate,this.toDate)}`)
                    .then(response => {
                        if(response.data.status === 'success'){
                            if(!id){
                                this.pageLoad = false;
                            
                                //table
                                this.table.data = response.data; 

                                //permission
                                this.list = response.data.list;
                                this.create = response.data.create;
                                this.deleteAll = response.data.delete;
                                this.edit = response.data.edit;

                                this.isTrue = false;

                    
                            }
                        } else if(response.data.status === 401) {
                            alertify.set('notifier','position', 'top-right');
                            alertify.error(`${response.data.message}`);
                            localStorage.removeItem('posUser');
                            this.$router.push('/Unauthenticated');
                        } else if(response.data.status == "is_permission_no"){
                            this.permision_no = true;
                        }
                        else {
                            console.log('something wrong with api');
                        }
                    })
                    .then(()=>{
                        this.reloadSelectOption();
                        $('#reservation').daterangepicker({
                            autoUpdateInput: true,
                            locale: {
                                cancelLabel: 'Clear',
                                format: 'DD/MM/YYYY'
                            },
                        });      
                        $('#reservation').on('apply.daterangepicker', function(ev, picker) {
                            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                        });

                        $('#reservation').on('cancel.daterangepicker', function(ev, picker) {
                            $(this).val('');
                        });
                    })
                    .catch(error => {
                            console.log(error)
                        }
                    );
            } else {
                this.$router.push('/Unauthenticated')
            }
        },
    },
    created(){
        this.getData(false);
    },
    mounted(){
      
    }
  }
  </script>
  
  <style>
  
  </style>